<template>
  <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M18.32,8H5.67L5.23,4H18.77M12,19A3,3 0 0,1 9,16C9,14 12,10.6 12,10.6C12,10.6 15,14 15,16A3,3 0 0,1 12,19M3,2L5,20.23C5.13,21.23 5.97,22 7,22H17C18,22 18.87,21.23 19,20.23L21,2H3Z"
    />
  </svg>
</template>

<script>
export default {
  name: "WaterDepositIcon",
};
</script>