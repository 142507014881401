<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.67 8H18.32L18.77 4H5.23L5.67 8ZM5 20.23L3 2H21L19 20.23C18.87 21.23 18 22 17 22H7C5.97 22 5.13 21.23 5 20.23ZM15 14.5C15 16.9853 13.6569 19 12 19C10.3431 19 9 16.9853 9 14.5C9 12.3022 10.0504 10.4724 11.4392 10.0785C11.1281 11.4183 11.2 12.8277 11.6598 14.1417L11.814 14.5824L11.8139 14.5824L11.8163 14.5886C12.3529 16.0005 12.4453 17.5384 12.0878 18.9979C12.2707 18.9901 12.4495 18.9576 12.6228 18.9028C12.9448 17.4105 12.8304 15.8512 12.2849 14.4141L12.1317 13.9766C11.6814 12.6898 11.6305 11.3048 11.9736 10.0002L12 10C13.6569 10 15 12.0147 15 14.5ZM11.9736 10.0002L11.9737 10C11.7912 10.0024 11.6126 10.0292 11.4392 10.0784L11.4392 10.0785C11.6125 10.0293 11.7911 10.0025 11.9736 10.0002Z"
      fill="black"
    />
  </svg>
</template>

<script>
export default {
  name: "CoffeeDepositIcon",
};
</script>